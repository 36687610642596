// Copyright 2020 @po-polochkam authors & contributors
import jwtDecode from 'jwt-decode';

// JWT = Header.Payload.Signature

export type JWTType = {
  aud: string;
  exp: number;
  iat: number;
  jti: string;
  nbf: number;
  scopes: Array<string>; // ROLE_CUSTOMER
  sub: string;
}

export function decodeToken (token: string): JWTType | null {
  try {
    // if the token has more or less than 3 parts or is not a string
    // then is not a valid token
    if (token.split('.').length !== 3) {
      return null;
    } else {
      console.log('jwtDecode(token)', jwtDecode(token));
      // decode and parse to json
      return jwtDecode(token);
    }
  } catch (error) {
    // Return null if something goes wrong
    return null;
  }
}

export function isTokenExpired (token: string): boolean {
  const decodedToken: JWTType | null = decodeToken(token);
  let result = true;

  if (decodedToken && decodedToken.exp) {
    const expirationDate: Date = new Date(0);

    expirationDate.setUTCSeconds(decodedToken.exp); // sets the expiration seconds
    // compare the expiration time and the current time
    result = expirationDate.valueOf() < new Date().valueOf();
  } else {
    result = true;
  }

  return result;
}

export default {
  decodeToken,
  isTokenExpired
};
