// Copyright 2020 @po-polochkam authors & contributors
import request from './request';
import {API_DICTIONARY, API_OPERATOR} from 'api/config';
import {ResultProduct} from "../abstractions/dictionary";

export const getReportMistakesTypes = async () => {
  const data = await request({
    url: `${API_DICTIONARY}report-mistakes-types`,
    method: 'GET'
  });

  return data.data;
}

export const getReportMistakesByType = async (typeId: string) => {
  const data = await request({
    url: `${API_DICTIONARY}report-mistakes-types/${typeId}/mistakes`,
    method: 'GET'
  });

  return data.data;
}

export const addMistakeToVisit = async (taskId: string, reportId:string, mistakeId: string, productsIds: string[]) => {
  const response = await request({
    url: `${API_OPERATOR}visit-review/tasks/${taskId}/reports/${reportId}/mistakes`,
    method: 'POST',
    data: {
      reportResultMistake: mistakeId,
      skuIds: productsIds
    },
    errorsMessages: {
      400: "Ошибка уже добавлена для этого отчета"
    }
  });

  return response.data;
};

export const addCustomMistakeToVisit = async (visitId: string, reportResultId:string, ownErrorText: string, productsIds: string[]) => {
  const data = await request({
    url: `${API_OPERATOR}visit-review/${visitId}/mistakes`,
    method: 'POST',
    data: {
      comment: ownErrorText,
      customerReportResult: reportResultId,
      productsIds: productsIds
    }
  });

  return data.data;
};

// export const deleteMistake = async (visitId: string, mistakeId: string) => {
//   const data = await request({
//     url: `${API_OPERATOR}/${visitId}/mistakes/${mistakeId}`,
//     method: 'DELETE'
//   });
//
//   return data.data;
// }

export const deleteMistake = async (taskId: string, reportId: string, mistakeId: string) => {
  const data = await request({
    url: `${API_OPERATOR}visit-review/tasks/${taskId}/reports/${reportId}/mistakes/${mistakeId}`,
    method: 'DELETE'
  });

  return data.data;
}

// export const getProductsByVisit = async (visitId: string, groupTaskId: string, { page, perPage }: { page?: number, perPage?: number }) => {
//   const data = await request({
//     url: `${API_OPERATOR}/visit-review/${visitId}/${groupTaskId}/skus`,
//     method: 'GET',
//     params: {
//       page,
//       'per-page': perPage,
//       'sort[name]': 'ASC'
//     }
//   });
//
//   return data.data;
// };

/**
 * Получаем список отсутствующих товаров
 * */
export const getProductsByTask = async ( groupTaskId: string, { page, perPage }: { page?: number, perPage?: number }): Promise<ResultProduct> => {
  const response = await request({
    url: `${API_OPERATOR}visit-review/tasks/${groupTaskId}/skus`,
    // url: `${API_OPERATOR}visit-review/tasks/${s}/skus`,
    method: 'GET',
    params: {
      page,
      'per-page': perPage,
      'sort[name]': 'ASC'
    }
  });

  return response.data as ResultProduct;
};