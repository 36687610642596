// Copyright 2020 @po-polochkam authors & contributors
import type { VisitReviewCounterInterface } from 'abstractions/dictionary';

import { useQuery } from 'react-query';

import request from 'api/request';
import { API_OPERATOR } from 'api/config';

export interface UseCitiesInterface {
  isError: boolean;
  loadingVisitReviewCounters: boolean;
  visitReviewCounters: VisitReviewCounterInterface | null;
}

export interface UseVisitReviewCountersProps {
  cityId?: string;
}

async function fetchVisitReviewCounters (cityId?: string): Promise<VisitReviewCounterInterface> {
  const { data } = await request({
    method: 'GET',
    params: {
      'city-id': cityId
    },
    url: `${API_OPERATOR as string}visit-review-counters`
  }) as { data: VisitReviewCounterInterface };

  return data;
}

// Данные по проверке отчетов
export default function useVisitReviewCounters ({ cityId }: UseVisitReviewCountersProps): UseCitiesInterface {
  const { data, isError, isLoading: loadingVisitReviewCounters } = useQuery(['VISIT_REVIEW_COUNTERS', cityId], () => {
    return fetchVisitReviewCounters(cityId);
  }, {
    cacheTime: 1500
  });

  return {
    isError,
    loadingVisitReviewCounters,
    visitReviewCounters: data as VisitReviewCounterInterface || null
  };
}
