// Copyright 2020 @po-polochkam authors & contributors

import React, { ChangeEvent, useCallback } from 'react';
import { Input } from 'antd';
import { TFunctionResult } from 'i18next';

import searchIcon from 'assets/svg/searchIcon.svg';
import clearIcon from 'assets/svg/clearIcon.svg';
import './styles.less';

interface SearchInputProps {
  defaultValue?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onClearSearch?: () => void;
  onPressEnter?: () => void;
  placeholder?: string | null | TFunctionResult;
  value?: string;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { defaultValue, disabled, onChange, onClearSearch, onPressEnter, placeholder, value } = props;

  const onChangeEvent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <div className={`search-input ${value && value.length ? 'has-value' : ''}`}>
      <Input
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChangeEvent}
        onPressEnter={onPressEnter}
        placeholder={placeholder ? placeholder.toString() : ''}
        prefix={
          <img
            alt='search-icon'
            src={searchIcon}
          />
        }
        suffix={
          !disabled && value && value.length && (
            <img
              alt='clear-icon'
              onClick={onClearSearch}
              src={clearIcon}
            />
          )
        }
        value={value}
      />
    </div>
  );
};

export default SearchInput;
