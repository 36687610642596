// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SearchDropdown from 'components/SearchDropdown';
import useCities from 'hooks/useCities';
import useReports from 'hooks/useReports';
import { ItemType, ReportType } from 'abstractions/dictionary';
import CheckboxCommon from 'uiKit/Checkbox/CheckboxCommon';
import useSelectedReport from 'hooks/useSelectedReport';
import { useQueryClient } from 'react-query';
import { HOME_PAGE_ROUTE } from 'config';
import { useUser } from 'hooks/useUser';
import { getUser } from 'api/manageToken';
import useBuildReport from 'hooks/useBuildReport';
import ReportsList from './components/ReportsList/ReportsList';
import { Divider, Button, notification } from 'antd';

import './SelectedReports.less';

moment.locale('ru');

type ReportsVerificationSelectedProps = {
  selectedCity: ItemType | undefined;
  setSelectedCity: (item: ItemType | undefined) => void;
}

const ERROR_TEXT = 'Нет отчетов для проверки';

function ReportsVerificationSelected (props: ReportsVerificationSelectedProps) {
  const { t } = useTranslation();
  const { selectedCity, setSelectedCity } = props;
  const [showOnlyRecommended, onToggleRecommended] = useState<boolean>(false);
  const [reportsToShow, setReportsToShow] = useState<ReportType[]>([]);
  const { cityId }: { cityId: string } = useParams();
  const queryClient = useQueryClient();
  const [term, setTerm] = useState<string | undefined>(selectedCity?.name);
  const { cities, loadingCities } = useCities({ term: '' });
  const history = useHistory();
  const { isFetched, reports } = useReports({ cityId });
  const userId = useMemo(() => getUser()?.userId || '', []);
  const { user } = useUser(userId);
  const { finish } = useSelectedReport();
  const { buildReport } = useBuildReport({ cityId });

  /**
   * Отбираем только рекоммендованные отчеты при изменении режима отображения
   * */
  useEffect(() => {
    setReportsToShow(() => {
      if (showOnlyRecommended) {
        return reports.filter((item) => item.isRecommendedForChecking);
      }

      return reports;
    });
  }, [reports, showOnlyRecommended]);

  const filterUniqueVisits = useMemo((): string[] => {
    const visitIds = reports.map((reportItem: ReportType) => reportItem.visitId);
    const unique: string[] = [];

    visitIds.forEach((id: string) => {
      if (!unique.includes(id)) {
        unique.push(id);
      }
    });

    return unique;
  }, [reports]);

  const finishVerification = useCallback(async () => {
    await finish(filterUniqueVisits);
    history.push(HOME_PAGE_ROUTE);
  }, [filterUniqueVisits, finish, history]);

  const continueVerification = useCallback(async () => {
    await finish(filterUniqueVisits);
    await buildReport();
    await queryClient.invalidateQueries('REPORTS_LIST_BY_CITY');
  }, [filterUniqueVisits, finish, buildReport, queryClient]);

  /**
   * Кидаем сообщение с ошибкой, если при повторном запросе пришло 0 отчетов
   * */
  useEffect(() => {
    console.log('enter data: ', isFetched, reports);

    if (isFetched && reports.length === 0) {
      console.log('open notification: ', isFetched, reports);
      notification.open({
        className: 'notification-error',
        duration: 3,
        key: 'requestError',
        message: ERROR_TEXT
      });
      history.push(HOME_PAGE_ROUTE);
    }
  }, [history, reports, isFetched]);

  return (
    <div className='selected-reports'>
      <h1>{t('reports verification')} - {user?.fullName ?? ''}</h1>
      <div className='selected-reports_header'>
        <SearchDropdown
          disabled
          items={cities}
          loading={loadingCities}
          onSelectItem={setSelectedCity}
          searchString={term}
          selected={selectedCity}
          setSearchString={setTerm}
        />
      </div>
      <CheckboxCommon
        checked={showOnlyRecommended}
        name={'showOnlyRecommended'}
        onChange={onToggleRecommended}
      >
        {t('show only recommended')}
      </CheckboxCommon>
      <Divider/>
      <ReportsList reports={reportsToShow}/>
      <Divider/>
      <div className='selected-reports_actions'>
        <Button
          onClick={finishVerification}
          size='large'
          type='primary'
        >
          {(t('finish verification') || '').toString()}
        </Button>
        <Button
          onClick={continueVerification}
          size='large'
          type='primary'
        >
          {(t('continue verification') || '').toString()}
        </Button>
      </div>
    </div>
  );
}

export default memo(ReportsVerificationSelected);
