// Copyright 2020 @po-polochkam authors & contributors
import { ReportType } from 'abstractions/dictionary';

/**
 * Функция для создания урла для виззита
 * @param visitId
 * @param geoAreaId
 */
export const createVisitUrl = (visitId: string, geoAreaId: string): string =>
  `${window.processEnv.BACKEND_URL}/visits/${visitId}/show/geoId/${geoAreaId}`;

/**
 * Функция для определения цвет границы
 * @param inShopAcceptance
 * @param isRecommendedForChecking
 * @param recognitionResult
 */
export const getBorderColor = ({ inShopAcceptance, isRecommendedForChecking, recognitionResult }: ReportType): string => {
  // recognitionResult - объект, было сказано в таске что "recognitionResult не пусто - к отчету применяется распознавание"
  const hasRecognitionResult = Boolean(Object.values(recognitionResult).filter(Boolean).length);
  let color = 'transparent';

  /**
     * Красная, если отчет размечен рек. системой
     */
  if (isRecommendedForChecking) {
    color = 'red';
  }

  /**
     * Желтая, если отчет не размечен рек.системой и к нему применяется распознавание
     */
  if (!isRecommendedForChecking && hasRecognitionResult) {
    color = 'yellow';
  }

  /**
     * Оранжевая - отчет по заданию с приемкой в магазине
     */
  if (inShopAcceptance) {
    color = 'orange';
  }

  return color;
};
