// Copyright 2022 @po-polochkam authors & contributors
import React from 'react';
import ErrorSelector from 'components/ErrorSelector';
import addItemIcon from 'assets/svg/addItem.svg';
import { ReportType } from 'abstractions/dictionary';

type Props = {
  cityId: string;
  report: ReportType;
}

/**
 * Компонент для выбора комментов-ошибок и вывод ошибок
 * @param cityId
 * @param report
 * @constructor
 */
const Errors: React.FC<Props> = ({ cityId, report }) => {
  return (
    <>
      <ErrorSelector
        allowClear
        category={report.productCategory}
        cityId={cityId}
        groupTaskId={report.groupTaskId}
        reportId={report.photoReport.id}
        suffixIcon={
          <img
            alt='add item'
            src={addItemIcon}
          />
        }
        visitId={report.visitId}
      />
      {report.reportMistakes.length > 0 && report.reportMistakes.map((mistake) => <ErrorSelector
        allowClear
        category={report.productCategory}
        cityId={cityId}
        groupTaskId={report.groupTaskId}
        key={mistake.id}
        mistake={mistake}
        reportId={report.photoReport.id}
        suffixIcon={
          <img
            alt='add item'
            src={addItemIcon}
          />
        }
        visitId={report.visitId}
      />)}
    </>
  );
};

export default React.memo(Errors);
