// Copyright 2020 @po-polochkam authors & contributors
import request from './request';
import { apiPath } from './config';

export const merchApi = 'auth/v1/';

export const loginUser = ({ username, password }: { username: string, password: string }) => request({
  url: `${merchApi}auth/token`,
  method: 'POST',
  data: {
    client_id: 'customer-lk-front',
    client_secret: 'customer_lk_secret',
    grant_type: 'password',
    username,
    password,
  }
});