// Copyright 2020 @po-polochkam authors & contributors
import { HOME_PAGE_ROUTE } from 'config';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { logout } from 'api/manageToken';

import ResultVerificationIcon from 'components/SVG/ResultVerificationIcon';
import FinishSession from 'components/SVG/FinishSessionIcon';
import logo from 'assets/svg/logo.svg';

import './styles.less';
import packageJson from '../../../package.json';

const { Sider } = Layout;
const { version } = packageJson;

interface SidebarMenuProps {
  collapsed: boolean;
}

const SidebarMenu:React.FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
  const { collapsed } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const goToHomePage = useCallback(() => {
    history.push(HOME_PAGE_ROUTE);
  }, [history]);

  const onLogout = useCallback(() => {
    logout();
  }, []);

  return (
    <Sider
      collapsed={collapsed}
      collapsible
      trigger={null}
      width={241}
    >
      <div className='logo-img'>
        <a onClick={goToHomePage}>
          <img
            alt={'po polochkam'}
            src={logo}
          />
          <span>
            {t('operator office')}
            <small>{t('version')} {version}</small>
          </span>
        </a>
      </div>
      <div className='top-sidebar-menu'>
        <Menu
          defaultSelectedKeys={['reports-verification']}
          mode='inline'
          theme='dark'
        >
          <Menu.Item
            icon={<ResultVerificationIcon />}
            key='reports-verification'
          >
            <Link to={HOME_PAGE_ROUTE}>{(t('reports verification') || '').toString()}</Link>
          </Menu.Item>
        </Menu>
      </div>
      <div className='bottom-sidebar-menu'>
        <Menu
          defaultSelectedKeys={[]}
          mode='inline'
          theme='dark'
        >
          {/* Footer menu */}
          {/* <Menu.Item
            icon={<FaqIcon />}
            key='faq'
          >
            <Link to='/home/faq'>{(t('faq') || '').toString()}</Link>
          </Menu.Item>
          <Menu.Item
            icon={<FaqIcon />}
            key='support'
          >
            <Link to='/home/support'>{(t('support') || '').toString()}</Link>
          </Menu.Item> */}
          <Menu.Item
            data-testid='logout'
            icon={<FinishSession />}
            key='finish_session'
          >
            <a onClick={onLogout}>{(t('finish the session') || '').toString()}</a>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

export default SidebarMenu;
