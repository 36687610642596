import request from './request';
import { API_OPERATOR } from './config';
import { ReportType } from 'abstractions/dictionary';

export const buildVisitReports = async (cityId: string):  Promise<number> => {
  const data = await request({
    url: `${API_OPERATOR}visit-review/reports`,
    method: 'POST',
    data: {
      city_id: cityId
    }
  });

  return data.data;
};

export const fetchVisitReports = async (cityId: string):  Promise<{ items: ReportType[] }> => {
  const data = await request({
    url: `${API_OPERATOR}visit-review/reports`,
    method: 'GET',
    params: {
      'city-id': cityId
    },
  });

  return data.data;
};