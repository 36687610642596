// Copyright 2020 @po-polochkam authors & contributors
import { useCallback } from 'react';
import { useMutation } from 'react-query';

import request from 'api/request';
import { API_OPERATOR } from 'api/config';

export type TaskState = 'accept' | 'decline';

export interface UseTaskStateInterface {
  accept: (reportId: string, taskId: string) => Promise<TaskActionType>;
  decline: (reportId: string, taskId: string) => Promise<TaskActionType>;
  finish: (visitIds: string[]) => Promise<TaskActionType>;
  toWaitingList: (visitId: string) => Promise<TaskActionType>;
}

export type TaskActionType = {
  status: 'success' | 'error';
}

export const tasksState = async (reportId: string, taskId: string, state: TaskState): Promise<TaskActionType> => {
  const data: { data: TaskActionType } = await request({
    method: 'POST',
    url: `${API_OPERATOR}visit-review/tasks/${taskId}/reports/${reportId}/${state}`
  });

  console.log('data', data);

  return data.data;
};

export const moveToWaitingList = async (visitId: string): Promise<TaskActionType> => {
  const data: { data: TaskActionType } = await request({
    data: {
      visitIds: [visitId]
    },
    method: 'POST',
    url: `${API_OPERATOR}visit-review/move-to-waiting-list`
  });

  console.log('data', data);

  return data.data;
};

export const finishVerification = async (visitIds: string[]): Promise<TaskActionType> => {
  const data: { data: TaskActionType } = await request({
    data: {
      visitIds
    },
    method: 'POST',
    url: `${API_OPERATOR}visit-review/end`
  });

  console.log('data', data);

  return data.data;
};

export default function useSelectedReport (): UseTaskStateInterface {
  const stateMutation = useMutation(({ reportId, state, taskId }: { taskId: string, reportId: string, state: TaskState }) => tasksState(reportId, taskId, state));
  const moveMutation = useMutation((visitId: string) => moveToWaitingList(visitId));
  const finishMutation = useMutation((visitIds: string[]) => finishVerification(visitIds));

  const accept = useCallback(async (reportId: string, taskId: string) => {
    return await stateMutation.mutateAsync({ reportId, state: 'accept', taskId });
  }, [stateMutation]);

  const decline = useCallback(async (reportId: string, taskId: string) => {
    return await stateMutation.mutateAsync({ reportId, state: 'decline', taskId });
  }, [stateMutation]);

  const toWaitingList = useCallback(async (visitId: string) => {
    return await moveMutation.mutateAsync(visitId);
  }, [moveMutation]);

  const finish = useCallback(async (visitIds: string[]) => {
    const result = await finishMutation.mutateAsync(visitIds);

    return result;
  }, [finishMutation]);

  return {
    accept,
    decline,
    finish,
    toWaitingList
  };
}
