// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { HOME_STARTER } from 'config';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { getUser } from 'api/manageToken';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import ReportsVerification from 'components/ReportsVerification';
import SidebarMenu from 'components/SidebarMenu';

import './styles.less';

const { Content, Header } = Layout;

const HomePage = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const showHeader = false;
  const history = useHistory();
  const location = useLocation();

  const toggle = useCallback(() => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  }, []);

  useEffect(() => {
    // check for user
    if (!getUser()) {
      history.push('/login');
    }
  }, [history, location]);

  return (
    <div
      className='home-page'
      data-testid='home-page'
    >
      <Layout>
        <SidebarMenu collapsed={collapsed} />
        <Layout className='site-layout'>
          {showHeader && (
            <Header
              className='site-layout-background'
              style={{ padding: 0 }}
            >
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle
              })}
            </Header>
          )}
          <Content
            className='site-layout-background'
            style={{
              margin: '0',
              minHeight: 280
            }}
          >
            <Switch>
              <Route
                path={HOME_STARTER}
              >
                <ReportsVerification />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default memo(HomePage);
