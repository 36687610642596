// Copyright 2020 @po-polochkam authors & contributors
export default {
  requestSuccessInterceptor(config: any) {
    return config;
  },
  requestErrorInterceptor(error: any) {
    return Promise.reject(error);
  },
  responseSuccessInterceptor(response: any) {
    return response;
  },
  async responseErrorInterceptor(error: any) {
    if (error.config && error.response) {
      switch (error.response.status) {
        case 400:
          console.log(`Ошибка сервера — ${error.response.data.message}`);
          break;
        case 401: {
          console.log('401 unauthorized', error);
          break;
        }
        case 403:
          console.log(`Отсутствуют полномочия — ${error.response.data.message}`);
          break;
        case 404:
          console.log(`Отсутствует страница — ${error.response.data.message}`);
          break;
        case 500:
          console.log(`Инфраструктурная ошибка — ${error.response.data.message}`);
          break;
        case 504:
          console.log(`Превышен лимит ожидания — ${error.response.data.message}`);
          break;
        case 406:
          console.log(`Логическая ошибка — ${error.response.data.message}`);
          break;
        case 409:
          console.log(`Ошибка конкурентного доступа — ${error.response.data.message}`);
          break;
        case 429:
          console.log(`Ошибка - превышено количество допустимых запросов`);
          break;

        default:
          console.log('Произошла неизвестная ошибка');
          break;
      }
    }

    return Promise.reject(error.response);
  },
}
