// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, ReactNode } from 'react';
import { Button } from 'antd';
import './styles.less';

interface ButtonCommonProps {
  block?: boolean;
  children?: any;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: ReactNode;
  loading?: boolean | { delay: number };
  shape?: 'circle' | 'round';
  size?: 'large' | 'middle' | 'small';
  target?: string;
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
  onClick?: () => void;
}

const ButtonCommon: React.FC<ButtonCommonProps> = (props) => {
  return (
    <Button
      {...props}
    />
  );
};

export default memo(ButtonCommon);
