// Copyright 2020 @po-polochkam authors & contributors
import { useCallback } from 'react';
import { useMutation } from 'react-query';

import request from 'api/request';
import { API_OPERATOR } from 'api/config';

export interface UseBuildReportsInterface {
  buildReport: () => Promise<number>;
}

export interface UseBuildReportsProps {
  cityId?: string;
}

export type ReportsCountType = {
  count: number;
}

export const buildVisitReports = async (cityId: string): Promise<ReportsCountType> => {
  const data: { data: ReportsCountType } = await request({
    data: {
      city_id: cityId
    },
    method: 'POST',
    url: `${API_OPERATOR}visit-review/reports`
  });

  return data.data;
};

export default function useBuildReport ({ cityId }: UseBuildReportsProps): UseBuildReportsInterface {
  const mutation = useMutation((cityId: string) => buildVisitReports(cityId));

  const buildReport = useCallback(async () => {
    if (cityId) {
      const report: ReportsCountType = await mutation.mutateAsync(cityId);

      return report.count;
    }

    return 0;
  }, [cityId, mutation]);

  return {
    buildReport
  };
}
