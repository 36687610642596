// Copyright 2020 @po-polochkam authors & contributors
import type { CityType } from 'abstractions/dictionary';

import { useQuery } from 'react-query';

import request from 'api/request';
import { API_CUSTOMER } from 'api/config';

export interface UseCitiesInterface {
  cities: CityType[];
  isError: boolean;
  loadingCities: boolean;
}

export interface UseCitiesProps {
  term?: string;
}

async function fetchCities (term?: string): Promise<CityType[]> {
  const { data } = await request({
    method: 'GET',
    params: {
      term
    },
    url: `${API_CUSTOMER as string}dictionary/cities`
  }) as { data: CityType[] };

  return data;
}

const CITIES_DEFAULT_LIST: CityType[] = [];

/*
keepPreviousData:
1. The data from the last successful fetch available while new data is being requested, even though the query key has changed.
2. When the new data arrives, the previous data is seamlessly swapped to show the new data.
3. isPreviousData is made available to know what data the query is currently providing you
 */
export default function useCities ({ term }: UseCitiesProps): UseCitiesInterface {
  const { data, isError, isLoading: loadingCities } = useQuery(['SEARCH_CITIES_TERM', term], () => {
    return fetchCities(term);
  }, {
    cacheTime: 1500,
    enabled: Boolean(term && term.length > 2)
    // keepPreviousData: true
  });

  return {
    cities: data || CITIES_DEFAULT_LIST,
    isError,
    loadingCities
  };
}
