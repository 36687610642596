// Copyright 2020 @po-polochkam authors & contributors

import React, { Fragment, useCallback } from 'react';
import { ReportType } from 'abstractions/dictionary';
import { getBorderColor } from '../../helpers';
import Magnifier from 'react-magnifier';
import cn from 'classnames';
import { MAGNIFIER_ZOOM_SIZE } from '../../config';
import Errors from '../../../components/Errors/Errors';
import useSelectedReport from 'hooks/useSelectedReport';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import ReportItemHeader from './components/ReportItemHeader';

import './ReportsList.less';
import { useAbsentProducts } from 'hooks/useAbsentProducts';

type ReportsListProps = {
  reports: ReportType[] | null;
}

enum ActionType {
  decline = 'decline',
  accept = 'accept',
}

/**
 * Получаем нужное действие по статусу отчета
 * Если отчет принят, то соответственно мы можем его отклонить
 * Если отчет отклонен, то соответственно мы можем его принять
 * */
const getActionTypeByBoolean = (accepted: boolean): ActionType => {
  return accepted ? ActionType.decline : ActionType.accept;
};

/**
 * По умолчанию, если все значения в поле recognitionResult равны null,
 * то считаем, что отчет НЕ был обработан сервисом распознавания
 * */
const isRecognizedByService = (item: ReportType): boolean => {
  if (!item.recognitionResult) {
    return false;
  }

  return Object.values(item.recognitionResult).some((item) => Boolean(item));
};

const ReportsList: React.FC<ReportsListProps> = ({ reports }: ReportsListProps) => {
  const { t: translate } = useTranslation();
  const { accept, decline, toWaitingList } = useSelectedReport();
  const queryClient = useQueryClient();
  const { cityId }: { cityId: string } = useParams();
  const absentProducts = useAbsentProducts(reports);

  const handleReportAction = useCallback(async (reportId: string, taskId: string, action: ActionType): Promise<void> => {
    if (action === ActionType.accept) {
      await accept(reportId, taskId);
    }

    if (action === ActionType.decline) {
      await decline(reportId, taskId);
    }

    await queryClient.invalidateQueries('REPORTS_LIST_BY_CITY');
  }, [decline, accept, queryClient]);

  const moveToWaitingList = useCallback(async (visitId: string) => {
    await toWaitingList(visitId);
    await queryClient.invalidateQueries('REPORTS_LIST_BY_CITY');
  }, [queryClient, toWaitingList]);

  return (
    <Fragment>
      {reports?.map((report, index) => (
        <div
          className='report-list-item'
          key={`${report.visitId}-${report.photoReport.id}`}
        >
          <ReportItemHeader report={report}/>
          <div className='report-list-item-wrapper'>
            <Magnifier
              className={cn('report-list-item-wrapper__magnifier', 'left-image', `left-image_border_${getBorderColor(report)}`)}
              mgHeight={MAGNIFIER_ZOOM_SIZE}
              mgWidth={MAGNIFIER_ZOOM_SIZE}
              src={report.photoReport.imagePath}
              zoomFactor={2}
            />
            <Magnifier
              className='report-list-item-wrapper__magnifier'
              mgHeight={MAGNIFIER_ZOOM_SIZE}
              mgWidth={MAGNIFIER_ZOOM_SIZE}
              src={report.photoReportResults.imagePath}
            />
            <div className='report-list-item-info'>
              <div className='report-list-item-actions'>
                <div
                  className={cn('report-list-item-actions__header',
                    `report-list-item-actions__header_${report.accepted ? 'accepted' : 'declined'}`)}>
                  {report.accepted ? translate('report accepted') : translate('report declined')}
                </div>
                <div className='report-list-item-actions__body'>
                  <Button
                    onClick={() => handleReportAction(report.photoReport.id, report.groupTaskId, getActionTypeByBoolean(report.accepted))}
                    size='large'
                    type='primary'
                  >
                    {(translate((report.accepted ? 'decline' : 'accept') || '').toString())}
                  </Button>
                  <Button
                    onClick={() => moveToWaitingList(report.visitId)}
                    size='large'
                    type='primary'
                  >
                    {(translate('to wait list') || '').toString()}
                  </Button>
                </div>
              </div>
              <Errors cityId={cityId}
                report={report}/>
              <div className='report-list-item-comments'>
                <div
                  className='report-list-item-comments__header'>{translate('performers comments')}</div>
                <div>{report.workerComment}</div>
              </div>
              {isRecognizedByService(report) && (
                <div className='report-list-item-recognition'>
                  <h6 className='report-list-item-recognition__header'>Отчёт по распознаванию</h6>
                  <span className={`report-list-item-recognition__result_${report.accepted ? 'accepted' : 'declined'}`}>Решение системы распознавания: {report.accepted ? translate('report accepted') : translate('report declined')}</span>
                  <span>Комментарий от системы распознавания: {report.recognitionResult.autoComment}</span>
                </div>
              )}
            </div>
            {/* /visit-review/tasks/{taskId}/skus */}
            {absentProducts[index] && absentProducts[index].length > 0 && (
              <div className='report-list-item-lack-of-products'>
                <div className='report-list-item-lack-of-products__header'>{translate('not in stock')}</div>
                {absentProducts[index].map((item) => (
                  <div
                    className='report-list-item-lack-of-products__product'
                    key={item.id}
                  >
                    <img
                      alt='product-image'
                      src={item?.imagePath}
                    />
                    <span>
                      {item.name}
                    </span>
                  </div>)
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default React.memo(ReportsList);
