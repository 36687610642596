// Copyright 2020 @po-polochkam authors & contributors
import React from 'react';

const FinishSession: React.FC = () => {
  return (
    <span className='menu-icon'>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="14" r="10" fill="#515153"/>
        <g filter="url(#filter0_b)">
          <path d="M23 14C23.5523 14 24.0037 13.5517 23.9643 13.0008C23.8572 11.5043 23.5101 10.0325 22.9343 8.64243C22.2307 6.94387 21.1995 5.40053 19.8995 4.1005C18.5995 2.80048 17.0561 1.76925 15.3576 1.06569C13.9675 0.489881 12.4957 0.142774 10.9992 0.0356989C10.4483 -0.00371663 10 0.447715 10 1L10 13C10 13.5523 10.4477 14 11 14H23Z" fill="white" fillOpacity="0.26"/>
        </g>
        <defs>
          <filter id="filter0_b" x="5" y="-4.96667" width="23.9667" height="23.9667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation="2.5"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
          </filter>
        </defs>
      </svg>
    </span>
  );
};

export default FinishSession;
