// Copyright 2020 @po-polochkam authors & contributors
import './styles.less';

import type { ItemType } from 'abstractions/dictionary';

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HOME_STARTER } from 'config';
import { useHistory } from 'react-router-dom';

import { notification, Spin } from 'antd';
import ButtonCommon from 'uiKit/Button';
import useCities from 'hooks/useCities';
import useBuildReport from 'hooks/useBuildReport';
import useVisitReviewCounters from 'hooks/useVisitReviewCounters';

import SearchDropdown from 'components/SearchDropdown';

interface ReportsVerificationAllProps {
  selectedCity: ItemType | undefined;
  setSelectedCity: (item: ItemType | undefined) => void;
}

function ReportsVerificationAll (props: ReportsVerificationAllProps) {
  const { selectedCity, setSelectedCity } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [term, setTerm] = useState<string>();
  const { buildReport } = useBuildReport({ cityId: selectedCity?.id });
  const { cities, loadingCities } = useCities({ term });
  const { loadingVisitReviewCounters, visitReviewCounters } = useVisitReviewCounters({ cityId: selectedCity?.id });

  const checkReports = useCallback(async () => {
    const reportsCount = await buildReport();

    if (selectedCity) {
      if (reportsCount > 0) {
        history.push(`${HOME_STARTER}/selected/${selectedCity.id}`);
      } else {
        notification.open({
          className: 'notification-error',
          description: `${selectedCity.name}`,
          duration: 3,
          key: 'requestError',
          message: 'Отсутствуют отчеты по городу'
        });
      }
    }
  }, [buildReport, history, selectedCity]);

  /**
   * Для зануления города при заходе на экран
   * */
  useEffect(() => {
    setSelectedCity(undefined);
  }, [setSelectedCity]);

  return (
    <div className='reports-verification-all'>
      <div className='reports-verification--column'>
        <h1>{t('reports verification')}</h1>
        <div className='city-search'>
          <SearchDropdown
            items={cities}
            loading={loadingCities}
            onSelectItem={setSelectedCity}
            searchString={term}
            selected={selectedCity}
            setSearchString={setTerm}
          />
          <ButtonCommon
            disabled={!selectedCity?.id}
            onClick={checkReports}
            size='large'
            type='default'
          >
            {t('check out')}
          </ButtonCommon>
        </div>
        { loadingVisitReviewCounters && (
          <div className='mu-spin-container'>
            <Spin
              key={'results-list'}
              size='large'
              tip={t('loading')}
            />
          </div>
        )}
        <div className='verification-table'>
          <h2>{t('ready for verification')}</h2>
          <div className='verification-table--row'>
            <span>{t('visits')}</span>
            <span>{visitReviewCounters?.visitsWithoutReviewer}</span>
          </div>
          <div className='verification-table--row'>
            <span>{t('marked reports')}</span>
            <span>{visitReviewCounters?.reportsWithoutReviewerRec}</span>
          </div>
          <div className='verification-table--row'>
            <span>{t('total reports')}</span>
            <span>{visitReviewCounters?.reportsWithoutReviewerAll}</span>
          </div>
        </div>
        <div className='verification-table'>
          <h2>{t('is being verifying')}</h2>
          <div className='verification-table--row'>
            <span>{t('visits')}</span>
            <span>{visitReviewCounters?.visitsWithReviewer}</span>
          </div>
          <div className='verification-table--row'>
            <span>{t('marked reports')}</span>
            <span>{visitReviewCounters?.reportsWithReviewerRec}</span>
          </div>
          <div className='verification-table--row'>
            <span>{t('total reports')}</span>
            <span>{visitReviewCounters?.reportsWithReviewerAll}</span>
          </div>
        </div>

        <h2>{t('verified by')}</h2>
        <span>{visitReviewCounters?.reviewersNames?.length ? visitReviewCounters?.reviewersNames?.join(', ') : ''}</span>
      </div>
    </div>
  );
}

export default memo(ReportsVerificationAll);
