// Copyright 2020 @po-polochkam authors & contributors
import { notification, Row, Col } from 'antd';
import { LoginResponse } from 'abstractions/user';

import React, { memo, ReactElement, useCallback } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';

import { object as YupObject, string as YupString } from 'yup';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikErrors } from 'formik';
import ButtonCommon from 'uiKit/Button';
import { loginUser } from 'api/user';
import { HOME_PAGE_ROUTE } from 'config';
import { setUserByToken } from 'api/manageToken';
import { decodeToken } from '../../api/jwtUtils';

interface Values {
  password: string;
  username: string;
}

const LoginForm = (): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const loginMutation = useMutation(loginUser, {
    onSuccess: () => {
      // Query Invalidations
      void queryClient.invalidateQueries('login');
    }
  });

  const initialValues: Values = {
    password: '',
    username: ''
  };

  const redirectToHomePage = useCallback(() => {
    history.push(HOME_PAGE_ROUTE);
  }, [history]);

  const onSubmit = useCallback(async (values: Values, { setErrors }: FormikHelpers<Values>) => {
    const result = await loginMutation.mutateAsync({
      ...values
    });

    const requestStatus = get(result, ['status'], null);
    const errors: FormikErrors<Values> = {};

    if (requestStatus === 401) {
      errors.password = (t('the user credentials were incorrect') || '').toString();
      errors.username = (t('the user credentials were incorrect') || '').toString();
      setErrors(errors);
    } else if (requestStatus === 429) {
      errors.password = (t('too many requests') || '').toString();
      errors.username = (t('too many requests') || '').toString();
      setErrors(errors);
    } else if (requestStatus === 200) {
      const requestData: LoginResponse = get(result, ['data'], null);

      const decodedToken = decodeToken(requestData.access_token);

      // Интерфейс оператора только для пользователя - админа.
      if (decodedToken?.scopes?.includes('ROLE_ADMIN') || decodedToken?.scopes?.includes('ROLE_OPERATOR')) {
        setUserByToken(requestData, values.username, values.username);

        redirectToHomePage();
      } else {
        notification.open({
          className: 'notification-error',
          description: t('you have to be an admin user'),
          duration: 5,
          key: 'requestError',
          message: t('login error')
        });
      }
    }
  }, [loginMutation, t, redirectToHomePage]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={() => (
        <Form name='LoginForm'>
          <Form.Item
            name='username'
          >
            <Input
              name='username'
              placeholder={(t('login') || '').toString()}
              type='text'
            />
          </Form.Item>
          <Form.Item
            name='password'
          >
            <Input
              name='password'
              placeholder={(t('password') || '').toString()}
              type='password'
            />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                name='submit'
              >
                <ButtonCommon
                  block
                  htmlType='submit'
                  type='primary'>
                  {(t('enter') || '').toString()}
                </ButtonCommon>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      validationSchema={
        YupObject().shape({
          password: YupString()
            .required((t('password is a required field') || '').toString()),
          username: YupString()
            .required((t('username is a required field') || '').toString())
        })
      }
    />
  );
};

export default memo(LoginForm);
