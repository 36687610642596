// Copyright 2020 @po-polochkam authors & contributors
import './styles.less';
import React, { memo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HOME_PAGE_ROUTE, HOME_STARTER } from 'config';
import ReportsVerificationAll from './all';
import SelectedVerificationReports from './SelectedReports';
import { ItemType } from 'abstractions/dictionary';

function ReportsVerification () {
  const [selectedCity, setSelectedCity] = useState<ItemType | undefined>();

  return (
    <div className='reports-verification'>
      <Switch>
        <Route
          exact
          key='all'
          path={HOME_PAGE_ROUTE}
        >
          <ReportsVerificationAll
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
          />
        </Route>
        <Route
          exact
          key='selected'
          path={`${HOME_STARTER}/selected/:cityId`}
        >
          <SelectedVerificationReports
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
          />
        </Route>
        <Redirect to={HOME_PAGE_ROUTE} />
      </Switch>
    </div>
  );
}

export default memo(ReportsVerification);
