// Copyright 2020 @po-polochkam authors & contributors

declare global {
  interface Window {
    processEnv: {
      BACKEND_URL: string;
    }
  }
}

export const apiPath = process.env.NODE_ENV === 'development' ? '/api/' : `${window.processEnv.BACKEND_URL}/api/`;
export const API_CUSTOMER = '/customer/v1/';
export const API_DICTIONARY = '/dictionary/v1/'
export const API_OPERATOR = '/operator/v1/';
