// Copyright 2020 @po-polochkam authors & contributors
import React from 'react';

const ResultVerificationIcon: React.FC = () => {
  return (
    <span className='menu-icon'>
      <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="21" height="21" rx="10.5" fill="#296FF6"/>
        <g filter="url(#filter0_b)">
          <path fillRule="evenodd" clipRule="evenodd" d="M26.225 0.813486C27.2777 1.89813 27.2777 3.65669 26.225 4.74134L15.4421 15.851C14.3893 16.9356 12.6825 16.9356 11.6297 15.851L6.23829 10.2962C5.18554 9.21152 5.18554 7.45296 6.23829 6.36831C7.29104 5.28366 8.99787 5.28366 10.0506 6.36831L12.8183 9.21988C13.2111 9.62459 13.8607 9.62458 14.2535 9.21988L22.4126 0.813486C23.4654 -0.271162 25.1722 -0.271162 26.225 0.813486Z" fill="#E3E3E3" fillOpacity="0.7"/>
        </g>
        <defs>
          <filter id="filter0_b" x="0.663293" y="-4.78544" width="31.1367" height="26.2354" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation="2.39272"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
          </filter>
        </defs>
      </svg>
    </span>
  );
};

export default ResultVerificationIcon;
