// Copyright 2020 @po-polochkam authors & contributors
import type { ItemType } from 'abstractions/dictionary';

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/SearchInput';
import './styles.less';

interface SearchDropdownProps {
  disabled?: boolean;
  items: ItemType[];
  loading: boolean | undefined;
  onSelectItem?: (item: ItemType | undefined) => void;
  searchString: string | undefined;
  selected: ItemType | undefined;
  setSearchString: (searchString: string | undefined) => void;
}

const SearchDropdown: React.FC<SearchDropdownProps> = (props) => {
  const {
    disabled,
    items,
    onSelectItem,
    searchString,
    selected,
    setSearchString
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const elem = useRef<HTMLDivElement>(null);

  const selectItem = useCallback((item: ItemType) => {
    setSearchString(item.name);
    onSelectItem && onSelectItem(item);
    setOpen(false);
  }, [onSelectItem, setSearchString]);

  const onClearSearch = useCallback(() => {
    setSearchString(undefined);
    onSelectItem && onSelectItem(undefined);
  }, [onSelectItem, setSearchString]);

  const handleClickOutside = useCallback((event: Event) => {
    const el = elem?.current;

    if (!el ||
      el.contains((event?.target as Node) || null) ||
      (event.target as HTMLElement).classList.contains('ant-dropdown-trigger')
    ) {
      return;
    }

    setOpen(false);
  }, []);

  const onSearch = useCallback((value) => {
    setSearchString(value);
  }, [setSearchString]);

  useEffect(() => {
    /* Предотвращаем повторное открытие дропдауна с помощью проверки searchString !== selected?.name
    * При подгрузке городов получаем selected.name, нажатие на который обновляет searchString, тем самым открывая дропдаун
    * */
    if (searchString && searchString !== selected?.name) {
      setOpen(true);
    }
  }, [searchString, selected]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  return (
    <div className='search-dropdown'>
      <div className='search-dropdown--search'>
        <SearchInput
          disabled={disabled}
          onChange={onSearch}
          onClearSearch={onClearSearch}
          placeholder={(t('city') || '').toString()}
          value={searchString}
        />
      </div>
      { open && (
        <div
          className='search-dropdown--list'
          ref={elem}
        >
          { items.map((item: ItemType) => (
            <div
              className={`search-dropdown--list--item ${selected?.id === item.id ? 'selected' : ''}`}
              key={item.id}
              onClick={selectItem.bind(null, item)}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
