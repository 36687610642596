// Copyright 2020 @po-polochkam authors & contributors
import type { ResultMistakeType, ResultMistake, ResultProduct, SmallProduct } from 'abstractions/dictionary';

import { useCallback, useState } from 'react';
import { addCustomMistakeToVisit,
  addMistakeToVisit,
  deleteMistake,
  getReportMistakesTypes,
  getReportMistakesByType,
  getProductsByTask } from 'api/dictionary';

export interface UseCitiesInterface {
  addCustomMistake: (resultId: string, reportResultId:string, ownErrorText: string, productsIds: string[]) => Promise<void>;
  addMistake: (taskId: string, reportId:string, mistakeId: string, productsIds: string[]) => Promise<void>;
  deleteVisitMistake: (taskId: string, reportId: string, mistakeId: string) => Promise<void>;
  fetchResultMistakeByType: (typeId: string) => void;
  fetchResultMistakeTypes: () => void;
  fetchVisitProducts: (groupTaskId: string) => void;
  mistakesByType: ResultMistake[];
  resultMistakeTypes: ResultMistakeType[];
  resultProducts: SmallProduct[];
}

export default function useReportMistakes (): UseCitiesInterface {
  const [resultMistakeTypes, setResultMistakeTypes] = useState<ResultMistakeType[]>([]);
  const [mistakesByType, setMistakesByType] = useState<ResultMistake[]>([]);
  const [resultProducts, setResultProducts] = useState<SmallProduct[]>([]);

  const addMistake = useCallback(async (taskId: string, reportId:string, mistakeId: string, productsIds: string[]) => {
    await addMistakeToVisit(taskId, reportId, mistakeId, productsIds);
  }, []);

  const addCustomMistake = useCallback(async (resultId: string, reportResultId:string, ownErrorText: string, productsIds: string[]) => {
    await addCustomMistakeToVisit(resultId, reportResultId, ownErrorText, productsIds);
  }, []);

  const deleteVisitMistake = useCallback(async (taskId: string, reportId: string, mistakeId: string) => {
    await deleteMistake(taskId, reportId, mistakeId);
  }, []);

  const fetchResultMistakeTypes = useCallback(async () => {
    const data = await getReportMistakesTypes() as ResultMistakeType[];

    setResultMistakeTypes(data);
  }, []);

  const fetchResultMistakeByType = useCallback(async (typeId: string) => {
    const data = await getReportMistakesByType(typeId) as ResultMistake[];

    setMistakesByType(data);
  }, []);

  const fetchVisitProducts = useCallback(async (groupTaskId: string) => {
    const data: ResultProduct = await getProductsByTask(groupTaskId, { page: 0, perPage: 300 });

    setResultProducts(data.skus);
  }, []);

  return {
    addCustomMistake,
    addMistake,
    deleteVisitMistake,
    fetchResultMistakeByType,
    fetchResultMistakeTypes,
    fetchVisitProducts,
    mistakesByType,
    resultMistakeTypes,
    resultProducts
  };
}
