// Copyright 2020 @po-polochkam authors & contributors

import React from 'react';
import { ReportType } from 'abstractions/dictionary';
import { createVisitUrl } from '../../../../helpers';
import { YANDEX_MAPS_SEARCH } from 'config/constants';

import './ReportItemHeader.less';

type ReportItemHeaderProps = {
  report: ReportType
}

const ReportItemHeader: React.FC<ReportItemHeaderProps> = ({ report }: ReportItemHeaderProps) => {
  return (
    <div className='report-item-header'>
      <div>{report.productCategory.name}</div>
      <div>
        <img
          alt='magnit'
          className='report-item-header__icon'
          src={report.tradeNetwork.icon}
        />
        {report.tradeNetwork.name}
      </div>
      <a
        className='report-item-header__link'
        href={`${YANDEX_MAPS_SEARCH}${report.shopAddress.street}`}
      >
        {report.shopAddress.street}
      </a>
      <div className='report-item-header__date'>
        {report.checkOutDatetime}
      </div>
      <strong>(
        <a
          className='report-item-header__link'
          href={createVisitUrl(report.visitId, report.geoAreaId)}
          rel='noreferrer'
          target='_blank'
        >
          {report.visitShortId}
        </a>)
      </strong>
    </div>
  );
};

export default React.memo(ReportItemHeader);
