// Copyright 2020 @po-polochkam authors & contributors
import type { ReportType } from 'abstractions/dictionary';

import { useQuery } from 'react-query';

import { fetchVisitReports } from 'api/visitReview';
import { useMemo } from 'react';

export interface UseReportsInterface {
  isError: boolean;
  loadingReports: boolean;
  reports: ReportType[];
  isFetched: boolean;
}

export interface UseReportsProps {
  cityId: string;
}

export default function useReports ({ cityId }: UseReportsProps): UseReportsInterface {
  const { data, isError, isFetched, isLoading: loadingReports } = useQuery(['REPORTS_LIST_BY_CITY', cityId], () => {
    return fetchVisitReports(cityId);
  }, {
    cacheTime: 1500
  });

  const reports = useMemo(() => {
    return data?.items || [];
  }, [data]);

  return {
    isError,
    isFetched,
    loadingReports,
    reports
  };
}
