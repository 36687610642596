// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback } from 'react';
import { TFunctionResult } from 'i18next';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import '../styles.less';

interface CheckboxCommonProps {
  autoFocus?: boolean;
  checked: boolean;
  children?: string | null | TFunctionResult;
  defaultChecked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  name?: string;
  onChange: (checked: boolean) => void;
}

const CheckboxCommon: React.FC<CheckboxCommonProps> = (props) => {
  const {
    autoFocus,
    checked,
    children,
    defaultChecked,
    disabled,
    indeterminate,
    name,
    onChange
  } = props;

  const onChangeCheckbox = useCallback((e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
  }, [onChange]);

  return (
    <Checkbox
      autoFocus={autoFocus}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      indeterminate={indeterminate}
      name={name}
      onChange={onChangeCheckbox}
    >
      {children}
    </Checkbox>
  );
};

export default memo(CheckboxCommon);
