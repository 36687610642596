// Copyright 2020 @po-polochkam authors & contributors

import { useEffect, useState } from 'react';
import { ReportType, SmallProduct } from 'abstractions/dictionary';
import { getProductsByTask } from 'api/dictionary';

/**
 * Получаем список отсутствующих товаров для текущего списка отчетов из визита
 * */
export const useAbsentProducts = (reports: ReportType[] | null): SmallProduct[][] => {
  const [absentProducts, setAbsentProducts] = useState<SmallProduct[][]>([]);

  useEffect(() => {
    if (!reports) {
      return;
    }

    const promises = reports.map((item) => {
      return getProductsByTask(item.groupTaskId, { page: 0, perPage: 300 });
    });

    void (async function () {
      const results = await Promise.all(promises);

      /**
       * Считаем отсутствующими товарами только те, у которых поле presence === 'no'
       * */
      const products = results.map((item) => {
        return item.skus.filter((item) => item.presence === 'no');
      });

      setAbsentProducts(products);
    })();
  }, [reports]);

  return absentProducts;
};
