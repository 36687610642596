// Copyright 2022 @po-polochkam authors & contributors
import request from 'api/request';
import { API_OPERATOR } from 'api/config';
import { useQuery } from 'react-query';

import { ShortUser } from 'abstractions/user';

const USER_QUERY_KEY = 'USER';

export type UseUserType = {
  isError: boolean;
  loadingUser: boolean;
  user: ShortUser | null;
}

export const fetchUser = async (userId: string): Promise<ShortUser> => {
  const { data }: { data: ShortUser } = await request({
    method: 'GET',
    url: `${API_OPERATOR}admin-users/${userId}`
  });

  return data;
};

export const useUser = (userId: string): UseUserType => {
  const { data, isError, isLoading: loadingUser } = useQuery([USER_QUERY_KEY, userId], () => {
    return fetchUser(userId);
  }, {
    cacheTime: 1500,
    enabled: Boolean(userId),
    retry: false
  });

  return {
    isError,
    loadingUser,
    user: data || null
  };
};
