// Copyright 2020 @po-polochkam authors & contributors

import { User, LoginResponse } from 'abstractions/user';
import { merchApi } from './user';
import { apiPath } from './config';
import { decodeToken } from './jwtUtils';
import {USER_KEY} from "../config/localstorageKeys";

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T> (
  request: RequestInfo,
  options?: any,
): Promise<HttpResponse<T>> {
  try {
    const response: HttpResponse<T> = await fetch(
      request,
      options
    );

    response.parsedBody = await response.json() as T;

    return response;
  } catch (e: any) {

    return e;
  }
}

export const getUser = (): User | null => {
  try {
    const lsValue = localStorage.getItem(USER_KEY);
    if (!lsValue) {
      return null
    };
    const user = JSON.parse(lsValue);
    return user as User;
  }
  catch (e) {
    return null;
  }
};

export const isAdmin = (): boolean => {
  const user: User | null = getUser();
  return !!user?.roles?.includes('ROLE_ADMIN');
}

export const isOperator = (): boolean => {
  const user: User | null = getUser();
  return !!user?.roles?.includes('ROLE_OPERATOR');
}

export const getToken = (): string | null => {
  const user = getUser();

  if (!user) {
    return null;
  }

  return user.token;
};

export const getRefreshToken = (): string | null => {
  const user = getUser();

  if (!user) {
    return null;
  }

  return user.refreshToken;
};

export const updateToken = async (): Promise<boolean | null> => {
  const user = getUser();
  const token = getToken();

  console.log('updateToken', user);

  if (user) {
    const refreshResponse: HttpResponse<LoginResponse>  = await http<LoginResponse>(`${apiPath}${merchApi}auth/token`, {
      method: 'POST',
      body: JSON.stringify({
        client_id: 'customer-lk-front',
        client_secret: 'customer_lk_secret',
        grant_type: 'refresh_token',
        refresh_token: user.refreshToken,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token || ''}`,
      }
    });

    console.log('refreshResponse', refreshResponse);

    if (!refreshResponse.parsedBody) {
      return null;
    } else if (!refreshResponse?.status?.toString().includes('20')) {
      logout();
      return null;
    }

    setUserByToken(refreshResponse.parsedBody, user.id, user.name);

    return true;
  } else {
    logout();
    return null;
  }
};

export function setUserByToken(token: LoginResponse, userId: string, userName: string): void {
  const decodedToken = decodeToken(token.access_token);

  const user = {
    id: userId,
    name: userName,
    token: token.access_token,
    expiresIn: token.expires_in,
    refreshToken: token.refresh_token,
    roles: decodedToken && decodedToken.scopes || [],
    userId: decodedToken?.sub
  };

  localStorage.setItem('user', JSON.stringify(user));
}

export const logout = () => {
  localStorage.removeItem('user');
  window.location.replace('/#/login');
};
