// Copyright 2020 @po-polochkam authors & contributors

import { default as axiosLib, AxiosInstance, AxiosRequestConfig } from 'axios';
import { RequestErrorType, RequestPromise } from 'abstractions/request';
import { notification } from 'antd';
import { apiPath } from './config';
import Interceptors from './interceptors';
import { getToken, updateToken } from './manageToken';

const axios: AxiosInstance = axiosLib.create({ baseURL: apiPath });
let isRefreshing = false;
let refreshQueue: Array<RequestPromise> = [];
const retries = 1;

axios.interceptors.request.use(
  Interceptors.requestSuccessInterceptor,
  Interceptors.requestErrorInterceptor,
);

axios.interceptors.response.use(
  Interceptors.responseSuccessInterceptor,
  Interceptors.responseErrorInterceptor,
);

/**
 * Дополняем тип, давая возможность задавать кастомные сообщения для каждого кода ответа
 * todo: зарефакторить позже таким образом, чтобы для одного кода ответа можно было задавать несколько возможных сообщений
 * todo: в зависимости от кода ошибки
 * */
type RequestConfigBase = AxiosRequestConfig & {
  errorsMessages?: Record<number, string>;
  displayNotificationOnError?: boolean;
}

const buffer: { [key: string]: any} = {};

const request = ({ displayNotificationOnError=true, ...options }: RequestConfigBase) => {
  const token = getToken();

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const onSuccess = (response: any) => {
    if (response.data && response.data.items) {
      response.data.items.forEach((item: any) => {
        buffer[item.id] = item;
      });
    }

    return response;
  };

  /*
  Наиболее рабочий вариант получился такой:
  1. Лезем с теми токенами, которые есть в хранилище
  2. Если получаем в интерцепторе 403, то через специальный synchronized блок его пытаемся обновить
  3. Если удалось, пишем новые токены в storage и работаем дальше
  4. Если нет, то пробрасываем ошибку в верхние слои и отправляем на страницу авторизации

  а, и соответственно, интерцептор делает перезапрос,
  если успешно обновил токены по 403, меняя хедеры.
  Поэтому, хедеры по всегда подставляются в запрос внутри интерцептора, чтобы их, если что, можно было заменить
   */
  const onError = (error: RequestErrorType) => {
    const {
      config,
      data,
      status,
      title,
    }: RequestErrorType = error;

    console.log('onError api response', error);

   if (displayNotificationOnError) {
     const openNotification = (message: string, description: string) => {
       notification.open({
         className: 'notification-error',
         key: 'requestError',
         message,
         description,
         duration: 3
       });
     };

     /** Для ошибок, которым задаём текст на фронте, не выводим код ответа от сервера*/
     openNotification(options?.errorsMessages?.[status] ? "" : status.toString(),
         options?.errorsMessages?.[status] || data?.error?.message || title || 'Ошибка в запросе');
   }

    if (status === 401 || status === 403) {
      // если мы не пытаемся залогиниться, а делаем что-то другое, то редиректить на логин.
      if (config && !config.url.includes('auth/token')) {
        config._retry =
          typeof config._retry === 'undefined' ? 0 : ++config._retry;

        if (config._retry === retries) {
          throw error;
        }

        console.log(`on request error ${status}, config._retry ${config._retry}, retries ${retries}, isRefreshing ${isRefreshing}`);

        // если токен еще не обновляли, то обновляем и резолвим запросы, которые был в очереди
        if (!isRefreshing) {
          isRefreshing = true;

          updateToken().then((res: any) => {
            if (res) {
              console.log('res to resolve queue', res, 'refreshQueue', refreshQueue, 'len', refreshQueue.length);
              /* console.log('res to resolve queue', res, 'refreshQueue', refreshQueue, 'len', refreshQueue.length);
              refreshQueue.forEach((v: RequestPromise) => v.resolve());
              refreshQueue = []; */
              // location.reload();
            } else {
              refreshQueue.forEach((v) => v.reject());
              refreshQueue = [];
            }
          }).finally(() => {
            isRefreshing = false;
          });
        }

        // иначе создаем новый промис с параметрами запроса и пишем его в очередь
        return new Promise((resolve, reject) => {
          refreshQueue.push({
            resolve: () => resolve(axios.request(config)),
            reject: () => reject(error)
          })
        })
      }
    }

    return error;
  };

  return axios(options).then(onSuccess).catch(onError);
};

export default request;
